import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';

const ContacoesDeHistoriasPage = ({data, ...props}) => {
  const pageTitle = "Contações de histórias";
  const keywords = "Contações de histórias, Leituras no Litoral";
  const description = "Contações de histórias do projeto Leituras no Litoral";

  const hasEvents = false;

  const events = () => {
    return (
      <div>
        {
          categoryDescription()
        }

        <div className='row u-margin-top'>
          {
            data.allMdx.nodes.map((node) => {
              const image = getImage(node.frontmatter.hero_image);
              const imageAlt = node.frontmatter.hero_image_alt;
              const to = `/contacoes-de-historias/${node.slug}`;
              const title = node.frontmatter.title;
              const date = node.frontmatter.date || '';
              const time = node.frontmatter.time || '';
              const location = node.frontmatter.location;
              const classification = node.frontmatter.classification;

              return (
                <div className='col-xs-12 col-sm-6' key={node.id}>
                  <article className='event-card'>
                    <Link
                      to={to}
                      title={title}
                    >
                      <div className='event-card__img'>
                        <GatsbyImage
                          image={image}
                          alt={imageAlt}
                          title={imageAlt}
                        />
                      </div>

                      <div className='event-card__body'>
                        <h2>
                          {title}
                        </h2>

                        <ul className='u-reset-list'>
                          {
                            date && date !== 'Data inválida'
                              ? <li><strong>Data:</strong> {date}</li>
                              : null
                          }
                          {
                            time
                              ? <li><strong>Horário:</strong> {time}</li>        
                              : null
                          }
                          <li><strong>Local:</strong> {location}</li>
                          <li><strong>Classificação indicativa:</strong> {classification}</li>
                        </ul>

                      </div>
                    </Link>
                  </article>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const categoryDescription = () => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Sobre</h2>

              <p>As contações de histórias serão realizadas por <strong>Rafael Di Lari</strong> em escolas municipais. Ao todo serão realizadas 50 sessões de contação de histórias onde serão narradas <strong>histórias pertencentes à tradição oral da cultura popular brasileira, assim como textos de escritores e escritoras negras</strong>, como contos, poesias, lendas, mitos, cordéis e itans.</p>

              <p>Compreendendo a contação de histórias como uma importante ferramenta no incentivo à leitura, as ações se desenvolverão sob o enfoque lúdico no narrar. Ou seja, propiciando espaços de brincadeira, fantasia e interatividade com os ouvintes visando o prazer de ler, contar e ouvir uma história.</p>
            </div>
          </div>

          <div className="wave wave--left wave--2"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Rafael Di Lari</h2>

              <p>
                Ator, contador de histórias, mediador de leituras, arte educador e produtor cultural.
                Bacharel em Artes Cênicas pela Faculdade de Artes do Paraná (2010), especialista em Contação de Histórias e Literatura Infantil Juvenil pela Faculdade de Ampére (2015) e em Arte e Educação pelo Centro Universitário de Tecnologia de Curitiba (2022).
              </p>

              <p>
                Atua na área da literatura (contação de histórias e mediação de leituras) desde 2007 com apresentações em diversas livrarias, escolas públicas e particulares e em eventos.
                Ministra cursos e oficinas regularmente e foi contemplado em diversos editais das leis de incentivo à cultura da Fundação Cultural de Curitiba e Secretaria de Estado da Cultura do Paraná.
              </p>

              <p>Desde 2016 é proprietário da Barbas de Molho Produções Artísticas. </p>

              <p><a href="http://www.rafaeldilari.com.br" target='_blank' rel="noreferrer" title='Ir para o site de Rafael Di Lari'>www.rafaeldilari.com.br</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      pageTitle={pageTitle}
      keywords={keywords}
      description={description}
      {...props}
    >
      {
        hasEvents ? events() : categoryDescription()
      }
    </Layout>
  )
};

// export const query = graphql`
//   query {
//     allMdx(
//       sort: {fields: frontmatter___date, order: ASC}
//       filter: {fileAbsolutePath: {regex: "/(contacoes-de-historias)/"}}
//     ) {
//       nodes {
//         frontmatter {
//           title
//           time
//           location
//           classification
//           date(formatString: "DD MMMM, YYYY", locale: "PT-BR")
//           hero_image {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 700
//                 aspectRatio: 1.33
//                 transformOptions: {
//                   fit: COVER
//                   cropFocus: ATTENTION
//                 }
//               )
//             }
//           }
//           hero_image_alt
//         }
//         id
//         slug
//       }
//     }
//   }
// `;

export default ContacoesDeHistoriasPage;